$color-brand-green-darker: #038874;
$color-brand-green-dark: #00AD92;
$color-brand-green: #4ABFAD;
$color-brand-green-light: #71D3C4;

$color-brand-red-darker: #9b2203;
$color-brand-red-dark: #C02600;
$color-brand-red: #F04E26;
$color-brand-red-light: #FF7452;

$color-brand-yellow-light: #EBBE5D;
$color-brand-yellow: #F5B52A;
$color-brand-yellow-dark: #C48600;
$color-brand-yellow-darker: #a37000;

$color-violet-darkest: #20202C;
$color-violet-dark: #303040;
$color-violet: #686887;
$color-violet-light: #BFBFD9;
$color-violet-lightest: #E2E2EE;

$color-gray: #808080;
$color-gray-light: #BFBFBF;
$color-gray-lightest: #f7f7f7;

$color-black: #000;
$color-white: #fff;

$font-primary: 'Bebas Neue', impact;
$font-secondary: 'Montserrat', sans-serif;
$font-bebas: 'Bebas Neue', impact;

$text-shadow: -0.1rem 0.1rem 0.1rem #303030;
$text-shadow-thin: -0.05rem 0.05rem 0.1rem #303030;

// BREAKPOINTS
//   --phone-sm: '320px';
//   --phone-md: '375px';
//   --phone-lg: '425px';
//   --tablet-sm: '560px';
//   --tablet-md: '640px';
//   --tablet-lg: '768px';
//   --laptop: '1024px';
//   --desktop: '1440px';
//
