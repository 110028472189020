.app {
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }

  &:has(.navbar--expanded) {
    main,
    footer {
      user-select: none;
    }
  }
}
