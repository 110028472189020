.section {
  max-width: 124rem;
  width: 100%;
  padding: 4.8rem 0;
  margin: 0 auto;
  clip-path: inset(0px 0px 0px -10px);

  &.section--full-bleed {
    max-width: unset;
  }

  &.section--events {
    .container {
      overflow: hidden;
    }

    .events {
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      gap: 5rem;
    }
  }
}

.container {
  max-width: 114rem;
  width: 100%;
  padding: 0 4rem;
  margin: 0 auto;
  
  @include media-max(425px) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}
