.error-page {
  display: grid;
  place-content: center;

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.2rem;
  
    &__logo-mark {
      width: 15.9rem;
      display: block;
      height: auto;
      transition: filter 0.1s;

      &:hover {
        filter: brightness(1.1);
      }

      &:active {
        filter: brightness(0.95);
      }
    }
  
    &__message {
      font-family: $font-bebas;
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
  }
}
