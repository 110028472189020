.event-box {
  --border-color: #{$color-gray-lightest};
  $border-color: var(--border-color);

  border: 1.5px solid $border-color;
  padding: 2rem;
  width: 100%;
  max-width: 50rem;
  text-align: center;

  &.event-box--green {
    --border-color: #{$color-brand-green-light};
  }
  &.event-box--yellow {
    --border-color: #{$color-brand-yellow-light};
  }
  
  .heading-quaternary {
    padding-bottom: 1.8rem;
    border-bottom: 1px solid $border-color;
  }

  &__dates-list {
    list-style: none;

    display: flex;
    flex-direction: column;
  }

  &__item:not(:last-child) {
    border-bottom: 1px dashed $border-color;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__date {
    font-size: max(1.7rem, 16px);
    font-weight: 500;
    margin-bottom: 0.7rem;
  }
}
