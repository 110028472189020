.video-box {
  width: 100%;
  display: flex;
  align-items: center;

  &--loading {
    padding-bottom: 0;
    overflow: initial;
  }

  &__loading-notification {
    display: flex;
    gap: 1rem;
    align-items: center;

    .spinner {
      position: relative;
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      background-color: currentColor;
    }

    .video-box__loading-text {
      flex: 1 1;
      line-height: 1.4;
    }

    .video-box__link.link {
      
    }
  }

  &__video-container {
    width: 100%;
    height: 100%;

    &--loading {
      display: none;
    }

    .video-box__video {
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
      border: 1px dashed $color-gray-light;
      transition: scale 0.1s;
      user-select: none;
  
      &--loading {
        display: none;
      }

      &:hover {
        scale: 0.995;
      }

      &--fixed-scale {
        scale: 1 !important;
      }
    }

    .video-box__caption {
      margin-top: 0.4rem;
      color: $color-gray-light;
    }
  }
}
