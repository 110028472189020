.footer {
  padding: 1.5rem 2rem;
  background-color: $color-violet-dark;
  z-index: 1;
  box-shadow: 0 -2px 10px rgba(black, 0.3);

  display: grid;
  place-content: center;
  place-items: center;
  row-gap: 1rem;

  &__links {
    list-style: none;

    display: flex;
    gap: min(12rem, 10vw);
  }

  &__link {
    display: grid;
    place-content: center;
  }

  &__icon {
    height: 3.1rem;
    fill: $color-gray-lightest;

    &--twitter {
      @include icon-solid(#5EBEE7);
    }

    &--youtube {
      @include icon-solid(#FF0000);
    }

    &--instagram {
      @include icon-solid(#FFDC80);
    }

    &--linkedin {
      @include icon-solid(#00a0dc);
    }

    &--tamu {
      @include icon-solid(#d6d3c4);
    }
  }

  &__copyright,
  &__credits {
    line-height: 1.5;
    text-align: center;
  }
}
