.question-box {
  $fill-color: var(--fill-color);
  $fill-color-hover: var(--fill-color-hover);
  $fill-color-active: var(--fill-color-active);
  $border-color: var(--border-color);
  --box-padding: 1.6rem 3rem;

  &--yellow {
    --fill-color: #{rgba(darken($color-brand-yellow-darker, 18%), 0.85)};
    --fill-color-hover: #{rgba(darken($color-brand-yellow-darker, 10%), 1)};
    --fill-color-active: #{rgba(darken($color-brand-yellow-darker, 14%), 1)};
    --border-color: #{lighten($color-brand-yellow-darker, 10%)};
  }

  &--red {
    --fill-color: #{rgba(darken($color-brand-red-darker, 8%), 0.85)};
    --fill-color-hover: #{rgba(darken($color-brand-red-darker, 0%), 1)};
    --fill-color-active: #{rgba(darken($color-brand-red-darker, 4%), 1)};
    --border-color: #{lighten($color-brand-red-darker, 30%)};
  }

  &--green {
    --fill-color: #{rgba(darken($color-brand-green-darker, 13%), 0.85)};
    --fill-color-hover: #{rgba(darken($color-brand-green-darker, 5%), 1)};
    --fill-color-active: #{rgba(darken($color-brand-green-darker, 9%), 1)};
    --border-color: #{lighten($color-brand-green-darker, 16%)};
  }

  &--purple {
    --fill-color: #{rgba(darken($color-violet, 30%), 0.85)};
    --fill-color-hover: #{rgba(darken($color-violet, 22%), 1)};
    --fill-color-active: #{rgba(darken($color-violet, 26%), 1)};
    --border-color: #{lighten($color-violet, 16%)};
  }

  @include media-max(425px) {
    --box-padding: 1.6rem 2.4rem;
  }

  &__header,
  &__answer {
    border: 2px solid $border-color;
  }

  &__header {
    position: relative;
    width: 100%;
    padding: var(--box-padding);
    padding-right: 2rem;
    background-color: $fill-color;
    transition: background-color 0.1s;
    cursor: pointer;

    display: flex;
    align-items: center;

    &:hover {
      background-color: $fill-color-hover;
    }

    &:active {
      background-color: $fill-color-active;
    }

    .question-box__question {
      flex: 1;
      margin-bottom: 0;
      font-size: max(2rem, 18px);
    }

    .dropdown-marker-container {
      margin-left: 1.2rem;

      .dropdown-marker {
        display: inline-block;
        justify-self: center;
        width: 2rem;
        height: 1rem;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        background-color: currentColor;

        &--up {
          rotate: z 180deg;
        }
      }
    }
  }

  &__answer {
    width: 100%;
    padding: var(--box-padding);
    border-top: none;
    background-color: rgba($color-black, 0.3);
  }
}
