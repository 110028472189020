.event {
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading-tertiary {
    width: 100vw;
    text-align: center;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: $color-violet;
  }

  .paragraphs {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
    font-size: 16px;
  }

  &__event-boxes {
    width: 100%;
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: 6rem;
    row-gap: 3rem;
  }
}
