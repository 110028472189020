*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  @include media-max(560px) {
    font-size: 50%;
  }
  @include media-max-height(560px) {
    font-size: 50%;
  }

  &.cursor--move {
    cursor: move;
  }
}

body {
  position: relative;
  background-color: $color-black;
  font-family: $font-secondary;
  color: $color-gray-lightest;
  font-size: 16px;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;

  &.scroll-disabled {
    overflow-y: hidden;
  }
}

::selection {
  background-color: rgba($color-brand-green-light, 0.9);
  color: $color-black;
  text-shadow: none !important;
}

picture,
img {
  user-select: none;
}

#page-background {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1000;
  $gradient-color: rgba(darken($color-violet-darkest, 16%), 0.925);

  display: grid;
  place-items: center;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 400%;
    height: 400%;
    background-image: linear-gradient($gradient-color, $gradient-color),
                      url('/images/colored-logo-background-tile.png');
    background-size: calc(1.2vw + 4rem);
    filter: blur(0.5px);
    rotate: z -22.332deg;
  }
}

#root {
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
}
